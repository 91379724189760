// A wrapper for https://github.com/malte-wessel/react-textfit
// The wrapper is needed to explicity pass height properties down so that multiline height does not exceed the parent.
import PropTypes from "prop-types"
import { Textfit } from "@outofaxis/react-textfit"
import "./fittty.scss"

const Fittty = ({ minSize, maxSize, multiLine, text }) => (
  <span className="fittty" styleName="fittty">
    <Textfit min={minSize} max={maxSize} mode={multiLine ? "multi" : "single"}>
      {text}
    </Textfit>
  </span>
)

Fittty.propTypes = {
  text: PropTypes.string.isRequired,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  multiLine: PropTypes.bool,
}

Fittty.defaultProps = {
  minSize: 5,
  maxSize: 36,
  multiLine: false,
}

export default Fittty
