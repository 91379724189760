/*global process, require*/

import ReactRailsUJS from "react_ujs"
import Rails from "@rails/ujs"

if (process.env.NODE_ENV === "development") {
  require("preact/debug")
}

import { oneTimeSetup, sharedSetup, logCustomDatadogAction } from "app/scripts/shared_setup"
import { attachNewBadgeForButtons } from "shared/scripts/new_badge_button.tsx"
import RubberBandPinchColor from "app/scripts/rubber_band_pinch_color"
import BackButton from "app/scripts/back_button"

// Add assets to the manifest here only in development to support non-compiled assets
if (process.env.NODE_ENV === "development") {
  require.context("images", true)
  require.context("fonts", true)
}

oneTimeSetup()
const initializeSharedSetup = () => {
  sharedSetup()
  attachNewBadgeForButtons()
}
document.addEventListener("DOMContentLoaded", initializeSharedSetup)

Rails.start()
// Remove previous event handlers and add new ones:
ReactRailsUJS.detectEvents()

window.App = window.App || {}
window.App = Object.assign(window.App, {
  storedFieldPath: (resolveUrl, liveChannelId = "") => {
    if (liveChannelId === "") {
      return `/stored_fields/options?resolve_url=${resolveUrl}`
    } else {
      return `/stored_fields/options?resolve_url=${resolveUrl}&live_channel_id=${liveChannelId}`
    }
  }
})
window.App.Comps = {}
window.App.Scripts = {
  RubberBandPinchColor,
  BackButton,
}
window.App.Utils = {
  logCustomDatadogAction,
}
